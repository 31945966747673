import { ApolloClient, HttpLink } from '@apollo/client';
import type { TypePolicies } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { cb } from '../widgetLibrary';
import { ApolloLink, concat } from 'apollo-link';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import AppError from './AppError';
import { cbEnv } from '../constants/cbEnv';
import typePolicies from './policies';

function getClient() {
  const { REACT_APP_API_URL = '' } = import.meta.env;

  const buildFetchURL = (baseURI: RequestInfo | URL, params: Record<string, string | number | boolean>) => {
    let fetchURL = baseURI + '?';

    Object.keys(params).forEach((key) => {
      if (!fetchURL.endsWith('?')) {
        fetchURL += '&';
      }

      const value = params[key] || '';
      if (isEmpty(value)) {
        fetchURL += key;
      } else {
        fetchURL += `${key}=${value}`;
      }
    });

    return fetchURL;
  };

  const customFetch = (uri: RequestInfo | URL, options?: RequestInit) => {
    const body = JSON.parse(options?.body as string);

    const operationName = get(body, 'operationName');
    const orgEvent = JSON.parse(sessionStorage.getItem('orgEvent') || '{}');
    const { asmtEventId, asmtEventTypeCd, asmtId, siteId, orgId } = orgEvent;

    const fetchURL = buildFetchURL(uri, {
      asmtEventId,
      asmtEventTypeCd,
      asmtId,
      cbEnv,
      operationName,
      orgId,
      siteId,
    });

    return fetch(
      // `${uri}?cbEnv=${cbEnv}&siteId=${TC:1201921}&orgId=${102579}&asmtId=${'99'}&asmtEventTypeCd=${'5'}&asmtEventId=${'1356'}&foo=bar`,
      fetchURL,
      options
    );
  };

  const httpLink = new HttpLink({
    uri: `${REACT_APP_API_URL}/graphql`,
    fetch: customFetch,
  });

  const authMiddleware = new ApolloLink((operation, forward) => {
    const authNToken = cb.core.iam.getAuthenticationToken();
    const authZToken = cb.core.iam.getAuthorizationToken();

    // Break out the token from 'CBLogin xxxx-xxxx-xxxx-xxxxx-xxxxxxx' format.
    const xAuthToken = authNToken.indexOf(' ') > -1 ? authNToken.split(' ')[1] : '';

    // Error Handling if any of the tokens are missing.
    if (isEmpty(xAuthToken)) {
      throw new AppError({ code: 'auth:missing_auth_info', message: 'Missing x-auth-token' });
    }

    if (isEmpty(authZToken)) {
      throw new AppError({ code: 'auth:missing_auth_info', message: 'Missing X-CB-Catapult-Authorization-Token' });
    }

    if (isEmpty(authNToken)) {
      throw new AppError({ code: 'auth:missing_auth_info', message: 'Missing X-CB-Catapult-Authentication-Token' });
    }

    operation.setContext({
      headers: {
        'x-auth-token': xAuthToken,
        'X-CB-Catapult-Authentication-Token': authNToken,
        'X-CB-Catapult-Authorization-Token': authZToken,
      },
    });

    return forward(operation);
  });

  const client = new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: typePolicies as TypePolicies,
    }),
    fetch: customFetch,
    link: concat(authMiddleware, httpLink),
  });

  // Update for Apollo Client 2.6.x.
  client.defaultOptions = {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      refetchWritePolicy: 'overwrite',
    },
    query: {
      fetchPolicy: 'cache-first',
    },
  };

  return client;
}

export { getClient };
